import axios from "axios";
import { elitaiEndpoints } from "endpoints";
import { DateTime } from "luxon";
import queryClient from "queryClient";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

import { ISimulation, REQSimulateBulk, RESSimulation } from "./types";

const listSimulations = async (from: DateTime, to: DateTime): Promise<RESSimulation[]> =>
    axios.get(elitaiEndpoints.simulation, {
        params: {
            from: from.toISODate(),
            to: to.toISODate(),
        },
    });
const simulateBulk = async (items: REQSimulateBulk): Promise<RESSimulation[]> =>
    axios.post(`${elitaiEndpoints.simulationBulk}`, { items });

const getSimulationsExcel = async (from: DateTime, to: DateTime): Promise<RESSimulation[]> =>
    axios.get(elitaiEndpoints.simulationExport, {
        params: {
            from: from.toISODate(),
            to: to.toISODate(),
        },
    });

const simulationsBulk = async (ids: string[], callback: (data: any) => void) => {
    await new Promise((resolve) =>
        fetch(`${elitaiEndpoints.simulationBulk}?ids=${ids.join(",")}`, {
            method: "GET",
            headers: {
                "Content-Type": "text/event-stream",
                "Cache-Control": "no-cache",
                Connection: "keep-alive",
            },
        }).then((res) => {
            const reader = res.body.getReader();
            const decoder = new TextDecoder();

            let buffer = "";
            reader.read().then(function processText({ done, value }) {
                if (done) {
                    resolve(true);
                    return;
                }

                buffer += decoder.decode(value, { stream: true });

                const lines = buffer.split("\n");
                buffer = lines.pop();

                const filteredLines = lines.filter((line) => line.trim() !== "");
                const content = {};

                filteredLines.forEach((line) => {
                    const data = JSON.parse(line);
                    Object.assign(content, data);
                });

                callback(content);

                return reader.read().then(processText);
            });

            return res;
        })
    );
};

export const useListSimulations = (from: DateTime, to: DateTime) =>
    useQuery<unknown, unknown, ISimulation[]>({
        queryFn: () =>
            listSimulations(from, to).then((res) =>
                res.map((simulation) => ({
                    ...simulation,
                    generationStart: DateTime.fromISO(simulation.generationStart),
                    generationEnd: DateTime.fromISO(simulation.generationEnd),
                    createdAt: DateTime.fromISO(simulation.createdAt),
                    updatedAt: DateTime.fromISO(simulation.updatedAt),
                }))
            ),
        queryKey: [elitaiEndpoints.simulation, from, to],
    });
export const useGenerateExcelMutation = () =>
    useMutation({
        mutationFn: ({ from, to }: { from: DateTime; to: DateTime }) =>
            getSimulationsExcel(from, to).then((res) => console.log(res)),
    });

export const useSimulateBulkMutation = () =>
    useMutation({
        mutationFn: (data: REQSimulateBulk) => simulateBulk(data),
        onSuccess: () => {
            queryClient.invalidateQueries(elitaiEndpoints.simulation);
            toast.success("Simulations started successfully");
        },
    });

export const useGetSimulationBulkMutation = () =>
    useMutation({
        mutationFn: async (props: { callback: (data: any) => void; ids: string[] }) =>
            simulationsBulk(props.ids, (data) => {
                props.callback(data);
            }),
        onSuccess: () => {
            queryClient.invalidateQueries(elitaiEndpoints.simulation);
        },
    });
