import axios from "axios";
import React, { useState } from "react";

type Props = {};

const ExportSimulation: React.FC<Props> = () => {
    const [from, setFrom] = useState<string>("");
    const [to, setTo] = useState<string>("");

    const handleDownload = async () => {
        if (!from || !to) {
            alert("Please provide both dates.");
            return;
        }

        const url = `http://localhost:9000/api/v1/simulation/export?from=${from}&to=${to}`;

        try {
            const response = await axios.get(url, {
                responseType: "blob",
            });

            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const urlBlob = window.URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = urlBlob;
            link.download = `simulation_export_${from}_${to}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            window.URL.revokeObjectURL(urlBlob);
        } catch (error) {
            alert(`Error: ${axios.isAxiosError(error) ? error.message : "Unknown Error"}`);
        }
    };

    return (
        <div style={{ padding: "20px", maxWidth: "400px", margin: "auto" }}>
            <h3>Simulation Export</h3>
            <div style={{ marginBottom: "10px" }}>
                <label htmlFor="from">
                    From:
                    <input
                        type="date"
                        id="from"
                        value={from}
                        onChange={(e) => setFrom(e.target.value)}
                        style={{ marginLeft: "10px", padding: "5px", width: "100%" }}
                    />
                </label>
            </div>
            <div style={{ marginBottom: "10px" }}>
                <label htmlFor="to">
                    To:
                    <input
                        type="date"
                        id="to"
                        value={to}
                        onChange={(e) => setTo(e.target.value)}
                        style={{ marginLeft: "10px", padding: "5px", width: "100%" }}
                    />
                </label>
            </div>
            <button
                onClick={handleDownload}
                style={{
                    padding: "10px 20px",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                }}
            >
                Export Excel
            </button>
        </div>
    );
};

export default ExportSimulation;
