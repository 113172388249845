import AuthGuard from "guards/AuthGuard";
import GuestGuard from "guards/GuestGuard";
import ElitAiSimulationsPage from "pages/elitai/simulations/ElitAiSimulationsPage";
import React, { LazyExoticComponent, Suspense, lazy } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";

import LoadingScreen from "../components/LoadingScreen";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import DashboardLayout from "../layouts/dashboard";

const Loadable = (Component: LazyExoticComponent<any>) => () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}>
            <Component />
        </Suspense>
    );
};

export default function Router() {
    return useRoutes([
        {
            path: "auth",
            children: [
                {
                    path: "login",
                    element: (
                        <GuestGuard>
                            <LoginPage />
                        </GuestGuard>
                    ),
                },
            ],
        },
        {
            path: "/",
            element: <Navigate to="/dashboard" replace />,
        },
        {
            path: "/dashboard",
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                { element: <Navigate to="/dashboard/home" replace />, index: true },
                { path: "home", element: <HomePage /> },
                {
                    path: "users",
                    children: [{ element: <UsersListPage />, index: true }],
                },
                {
                    path: "patients",
                    children: [
                        { element: <PatientsListPage />, index: true },
                        { path: ":id", element: <PatientDetailsPage /> },
                        // { path: ':id/edit', element: <PageTwo /> }
                    ],
                },
                {
                    path: "strings",
                    children: [{ element: <StringsListPage />, index: true }],
                },
                {
                    path: "promotions",
                    children: [{ element: <PromotionsManagerPage />, index: true }],
                },
                {
                    path: "extra-treatments",
                    children: [{ element: <ExtraTreatmentsPage />, index: true }],
                },
                {
                    path: "machine-caller",
                    children: [
                        // { element: <MachineCallerListPage />, index: true },
                        { element: <Navigate to="/dashboard/machine-caller/results" replace />, index: true },
                        { path: "results", element: <MachineCallerListPage /> },
                    ],
                },
                {
                    path: "offers",
                    children: [
                        { element: <OffersListPage />, index: true },
                        // { path: 'create/:id', element: <CreateOfferPage /> },
                    ],
                },
                {
                    path: "templates",
                    children: [
                        { element: <TemplateListPage />, index: true },
                        { element: <TemplateForm />, path: "editTemplate/:id" },
                        // { element: <SectionsListPage />, path:'editTemplate/:id' }
                    ],
                },
                {
                    path: "elitai",
                    children: [
                        {
                            path: "simulations",
                            element: <ElitAiSimulationsPage />,
                            index: true,
                        },
                    ],
                },
                {
                    path: "sections",
                    children: [
                        { element: <SectionListPage />, index: true },
                        { element: <SectionForm />, path: "editSection/:id" },
                    ],
                },
                {
                    path: "prp-pos",
                    children: [{ element: <PointOfSalePage />, index: true }],
                },
                {
                    path: "hp-pos",
                    children: [{ element: <PointOfSalePage />, index: true }],
                },
            ],
        },
        {
            path: "/dashboard/offers/create/:id",
            element: (
                <AuthGuard>
                    <CreateOfferPage />
                </AuthGuard>
            ),
        },
        {
            path: "/dashboard/offers/:id",
            element: <ViewOfferPage />,
        },
        {
            path: "*",
            element: <LogoOnlyLayout />,
            children: [
                { path: "404", element: <NotFound /> },
                { path: "*", element: <Navigate to="/404" replace /> },
            ],
        },
        { path: "*", element: <Navigate to="/404" replace /> },
    ]);
}

// Auth
const LoginPage = Loadable(lazy(() => import("../pages/auth/LoginPage")));

// Offers
const CreateOfferPage = Loadable(lazy(() => import("../pages/dashboard/offers/CreateOfferPage")));
const ViewOfferPage = Loadable(lazy(() => import("../pages/dashboard/offers/ViewOfferPage")));

// Dashboard
const HomePage = Loadable(lazy(() => import("../pages/dashboard/home/HomePage")));
const UsersListPage = Loadable(lazy(() => import("../pages/dashboard/users/UsersListPage")));
const PatientsListPage = Loadable(lazy(() => import("../pages/dashboard/patients/PatientsListPage")));
const PatientDetailsPage = Loadable(lazy(() => import("../pages/dashboard/patients/PatientDetailsPage")));
const StringsListPage = Loadable(lazy(() => import("../pages/dashboard/strings/StringsListPage")));
const PromotionsManagerPage = Loadable(lazy(() => import("../pages/dashboard/promotions/PromotionsManagerPage")));
const ExtraTreatmentsPage = Loadable(lazy(() => import("../pages/dashboard/extra-treatments/ExtraTreatmentsPage")));
const MachineCallerListPage = Loadable(lazy(() => import("../pages/dashboard/twilio/MachineCallerListPage")));
const OffersListPage = Loadable(lazy(() => import("../pages/dashboard/offers/OffersListPage")));
const PointOfSalePage = Loadable(lazy(() => import("../pages/dashboard/payment/PointOfSale")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
// const TemplateListPage = Loadable(lazy(() => import('../pages/dashboard/templates/TemplatesListPage')));
const TemplateListPage = Loadable(lazy(() => import("../pages/dashboard/templates/templateListPageModified")));
const TemplateForm = Loadable(lazy(() => import("../pages/dashboard/templates/TemplateForm")));
const SectionListPage = Loadable(lazy(() => import("../pages/dashboard/sections/SectionsListPage")));
const SectionForm = Loadable(lazy(() => import("../pages/dashboard/sections/SectionForm")));
