import { Box } from "@mui/material";
import React, { forwardRef } from "react";
import { Helmet } from "react-helmet-async";

const Page = forwardRef<any, React.ComponentProps<typeof Box> & {meta?: string}>(({ children, title = "", meta, ...other }, ref) => (
    <>
        <Helmet>
            <title>{`${title} | Elithair`}</title>
            {meta}
        </Helmet>

        <Box ref={ref} sx={{ height: "100%" }} {...other}>
            {children}
        </Box>
    </>
));

export default Page;
