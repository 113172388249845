// @mui
import { MenuItem, Stack } from "@mui/material";
import i18next from "i18next";
import { useState } from "react";

// components
import Image from "../../../components/Image";
import MenuPopover from "../../../components/MenuPopover";
import { IconButtonAnimate } from "../../../components/animate";

// ----------------------------------------------------------------------

const LANGS = [
    {
        label: "English",
        value: "en",
        icon: "/icons/ic_flag_en.svg",
    },
    {
        label: "German",
        value: "de",
        icon: "/icons/ic_flag_de.svg",
    },
    {
        label: "Turkey",
        value: "tr",
        icon: "/icons/ic_flag_tr.svg",
    },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleChange = (lang) => {
        LANGS.find((option) => option.value === lang);
        i18next.changeLanguage(lang);
        localStorage.setItem("lang", lang);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const selectedLang = LANGS.find((lang) => lang.value === i18next.language) || LANGS[0];

    return (
        <>
            <IconButtonAnimate
                onClick={handleOpen}
                sx={{
                    width: 40,
                    height: 40,
                    ...(open && { bgcolor: "action.selected" }),
                }}
            >
                <Image disabledEffect src={selectedLang.icon} alt={selectedLang.label} />
            </IconButtonAnimate>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    mt: 1.5,
                    ml: 0.75,
                    width: 180,
                    "& .MuiMenuItem-root": { px: 1, typography: "body2", borderRadius: 0.75 },
                }}
            >
                <Stack spacing={0.75}>
                    {LANGS.map((option) => (
                        <MenuItem
                            key={option.value}
                            selected={option.value === i18next.language}
                            onClick={() => {
                                handleClose();
                                handleChange(option.value);
                            }}
                        >
                            <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>
            </MenuPopover>
        </>
    );
}
