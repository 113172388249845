import React from "react";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import "./axiosConfig";
import { ProgressBarStyle } from "./components/ProgressBar";
import ScrollToTop from "./components/ScrollToTop";
import ThemeColorPresets from "./components/ThemeColorPresets";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import LanguageProvider from "./providers/LanguageProvider";
import queryClient from "./queryClient";
import Router from "./routes";
import ThemeProvider from "./theme";

// ----------------------------------------------------------------------

export default function App() {
    return (
        <React.StrictMode>
            <LanguageProvider>
                <QueryClientProvider client={queryClient}>
                    <ThemeProvider>
                        <ThemeColorPresets>
                            <MotionLazyContainer>
                                <ProgressBarStyle />

                                <ScrollToTop />
                                <Router />

                                <ToastContainer />
                            </MotionLazyContainer>
                        </ThemeColorPresets>
                    </ThemeProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </LanguageProvider>
        </React.StrictMode>
    );
}
