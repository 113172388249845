// components
import CameraIcon from "@mui/icons-material/Camera";
import EmojiFoodBeverageIcon from "@mui/icons-material/EmojiFoodBeverage";
import GroupsIcon from "@mui/icons-material/Groups";
import InventoryIcon from "@mui/icons-material/Inventory";
import PaymentIcon from "@mui/icons-material/Payment";
import RequestQuote from "@mui/icons-material/RequestQuote";
import TranslateIcon from "@mui/icons-material/Translate";
import { useTranslation } from "react-i18next";

import SvgIconStyle from "../../../components/SvgIconStyle";

// import HandshakeIcon from '@mui/icons-material/Handshake';
// import AssessmentIcon from '@mui/icons-material/Assessment';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
    user: getIcon("ic_user"),
    translate: <TranslateIcon />,
    ecommerce: getIcon("ic_ecommerce"),
    analytics: getIcon("ic_analytics"),
    dashboard: getIcon("ic_dashboard"),
    invoice: getIcon("ic_invoice"),
    payment: <PaymentIcon />,
    groups: <GroupsIcon />,
    foodIcon: <EmojiFoodBeverageIcon />,
    requestQuoteIcon: <RequestQuote />,
    inventoryIcon: <InventoryIcon />,
    simulations: <CameraIcon />,
};

const SidebarConfig = () => {
    const { t } = useTranslation();

    return [
        // ----------------------------------------------------------------------
        {
            subheader: t("general"),
            items: [
                { title: t("offers"), path: "/dashboard/offers", icon: ICONS.invoice },
                {
                    title: t("templates"),
                    path: "/dashboard/templates",
                    icon: ICONS.ecommerce,
                },
                {
                    title: t("sections"),
                    path: "/dashboard/sections",
                    icon: ICONS.foodIcon,
                },
                // { title: 'Two', path: '/dashboard/two', icon: ICONS.ecommerce },
                // { title: 'Three', path: '/dashboard/three', icon: ICONS.analytics },
            ],
        },

        // Mobile APP MANAGEMENT
        // ----------------------------------------------------------------------
        {
            subheader: t("mobile_app"),
            items: [
                {
                    title: t("app_users"),
                    path: "/dashboard/patients",
                    icon: ICONS.user,
                },
                {
                    title: t("strings"),
                    path: "/dashboard/strings",
                    icon: ICONS.translate,
                },
                {
                    title: t("extra_treatments"),
                    path: "/dashboard/extra-treatments",
                    icon: ICONS.inventoryIcon,
                },
                {
                    title: t("promotions"),
                    path: "/dashboard/promotions",
                    icon: ICONS.requestQuoteIcon,
                },
            ],
        },

        // ELIT AI
        // ----------------------------------------------------------------------
        {
            subheader: "Elit AI",
            items: [
                {
                    title: t("simulations"),
                    path: "/dashboard/elitai/simulations",
                    icon: ICONS.simulations,
                },
            ],
        },

        // TWILIO
        // ----------------------------------------------------------------------
        {
            subheader: "Twilio",
            items: [
                {
                    title: t("machine_caller"),
                    path: "/dashboard/machine-caller",
                    icon: ICONS.user,
                },
            ],
        },

        // PATIENTS
        // ----------------------------------------------------------------------
        // {
        //   subheader: 'Patients',
        //   items: [
        //     { title: 'PRP Patients', path: '/dashboard/prp-patients', icon: ICONS.groups },
        //     { title: 'HP Patients', path: '/dashboard/hp-patients', icon: ICONS.groups },
        //   ],
        // },

        // PAYMENT
        // ----------------------------------------------------------------------
        // {
        //   subheader: 'Payment',
        //   items: [
        //     { title: 'PRP Point of Sale', path: '/dashboard/prp-pos', icon: ICONS.payment },
        //     { title: 'HP Point of Sale', path: '/dashboard/hp-pos', icon: ICONS.payment },
        //   ],
        // },

        // AFFILIATE
        // ----------------------------------------------------------------------
        // {
        //   subheader: 'Affiliate',
        //   items: [
        //     { title: 'Partners', path: '/dashboard/partners', icon: <HandshakeIcon /> },
        //     { title: 'Reports', path: '/dashboard/partner-reports', icon: <AssessmentIcon /> },
        //   ],
        // },

        // SETTINGS
        // ----------------------------------------------------------------------
        {
            subheader: t("settings"),
            items: [{ title: "Users", path: "/dashboard/users", icon: ICONS.user }],
        },
    ];
};

export default SidebarConfig;
