import { Avatar, Box, Divider, MenuItem, Stack, Typography } from "@mui/material";
// @mui
import { alpha } from "@mui/material/styles";
import useAuth from "hooks/useAuth";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import createAvatar from "utils/createAvatar";

// components
import MenuPopover from "../../../components/MenuPopover";
import { IconButtonAnimate } from "../../../components/animate";

export default function AccountPopover() {
    const { user, logout } = useAuth();
    const { t } = useTranslation();
    const [open, setOpen] = useState(null);
    const fullName = `${user?.firstName} ${user?.lastName}`;

    // ----------------------------------------------------------------------

    const MENU_OPTIONS = [
        {
            label: t("dashboard"),
            linkTo: "/dashboard",
        },
    ];

    // ----------------------------------------------------------------------

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    return (
        <>
            <IconButtonAnimate
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        "&:before": {
                            zIndex: 1,
                            content: "''",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            position: "absolute",
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                <Avatar
                    src={`${user?.avatarPath}/${user?.avatar}`}
                    alt={fullName}
                    color={user?.avatar ? "default" : createAvatar(fullName).color}
                >
                    {createAvatar(fullName).name}
                </Avatar>
            </IconButtonAnimate>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    "& .MuiMenuItem-root": {
                        typography: "body2",
                        borderRadius: 0.75,
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {fullName}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                        {user?.email}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: "dashed" }} />

                <Stack sx={{ p: 1 }}>
                    {MENU_OPTIONS.map((option) => (
                        <MenuItem key={option.label} to={option.linkTo} onClick={handleClose}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{ borderStyle: "dashed" }} />

                <MenuItem onClick={logout} sx={{ m: 1 }}>
                    {t("logout")}
                </MenuItem>
            </MenuPopover>
        </>
    );
}
