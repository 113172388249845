import { Button, Dialog, DialogTitle } from "@mui/material";
import { DateTime } from "luxon";

import { useGenerateExcelMutation } from "../helpers/simulations.queries";

type GenerateExcelDialogProps = {
    open: boolean;
    onClose: () => void;
};

export const GenerateExcelDialog = ({ open, onClose }: GenerateExcelDialogProps) => {
    const { mutateAsync: generateExcel } = useGenerateExcelMutation();

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitle>Generate Excel</DialogTitle>
            <Button
                onClick={() =>
                    generateExcel({
                        from: DateTime.now().minus({ days: 7 }).startOf("day"),
                        to: DateTime.now().endOf("day"),
                    })
                }
            >
                Generate
            </Button>
        </Dialog>
    );
};
