import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import i18n from "i18next";
import { ReactNode } from "react";
import { I18nextProvider, initReactI18next } from "react-i18next";

import de from "../locales/de.json";
import en from "../locales/en.json";
import tr from "../locales/tr.json";

i18n.use(initReactI18next).init({
    lng: localStorage.getItem("lang") || "tr",
    fallbackLng: "tr",

    resources: {
        en: { translation: en },
        tr: { translation: tr },
        de: { translation: de },
    },
});

const LanguageProvider = ({ children }: { children: ReactNode }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n.language || "tr"}>
            <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
        </LocalizationProvider>
    );
};

export default LanguageProvider;
